import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { urls } from "@/urls";
import { AlertCircle } from "lucide-react";
import { useContext, createContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

type LoginData = {
  username: string;
  password: string;
};

type Context = {
  token: string;
  login: (data: LoginData) => void;
  logout: () => void;
  checkAuth: () => boolean;
};

const AuthContext = createContext<Context>({
  token: "",
  login: (data: LoginData) => {
    data;
  },
  logout: () => {},
  checkAuth: () => false,
});

const AuthProvider = () => {
  const [isError, setIsError] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();
  const login = async (data: LoginData) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_ENDPOINT}/accounts/login/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        },
      );
      const res = await response.json();
      if (res.token) {
        localStorage.setItem("site", res.token);
        setToken(res.token);
        setTimeout(() => navigate(urls.admin.dashboard), 1500);
        return;
      }
      setIsError(true);
      setTimeout(() => setIsError(false), 3000);
    } catch (err) {
      console.error(err);
    }
  };

  const logout = () => {
    setToken("");
    localStorage.removeItem("site");
    navigate(urls.login);
  };

  const checkAuth = () => {
    if (token != "" || localStorage.getItem("site")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, checkAuth }}>
      {isError && (
        <div className="fixed flex justify-center w-full top-8">
          <Alert variant="destructive" className="w-auto">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              Email or Password is incorrect. Please try again.
            </AlertDescription>
          </Alert>
        </div>
      )}
      <Outlet />
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
